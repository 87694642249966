import {
  MatchDetailItemFragment,
  MatchEventFragment,
  MatchListItemFragment,
  TeamListItemFragment,
} from '@amf/shared/types/graphql';
import produce from 'immer';
import { useMemo } from 'react';
import { sdk } from '@amf/shared/lib/graphQlClient';
import { TeamMatchesFilterData } from '@amf/shared/components/team/TeamMatchesForm';
import gql from 'graphql-tag';
import { MatchSortOrder } from '@amf/shared/data/hooks/useMatches';

export type MatchContumacy = 'home' | 'away' | 'both' | null;

export enum MatchEventType {
  goal = 'goal',
  penalty = 'penalty',
  yellow = 'yellow',
  red = 'red',
  matchStart = 'match_start',
  matchEndFirstHalf = 'match_end_first_half',
  matchStartSecondHalf = 'match_start_second_half',
  matchEndSecondHalf = 'match_end_second_half',
  matchStartFirstExtension = 'match_start_first_extension',
  matchEndFirstExtension = 'match_end_first_extension',
  matchStartSecondExtension = 'match_start_second_extension',
  matchEndSecondExtension = 'match_end_second_extension',
  matchStartPenalty = 'match_start_penalty',
  matchEnd = 'match_end',
}

export function isSystemMatchEvent(event: MatchEventFragment): boolean {
  return [
    MatchEventType.matchStart,
    MatchEventType.matchEndFirstHalf,
    MatchEventType.matchStartSecondHalf,
    MatchEventType.matchEndSecondHalf,
    MatchEventType.matchStartFirstExtension,
    MatchEventType.matchEndFirstExtension,
    MatchEventType.matchStartSecondExtension,
    MatchEventType.matchEndSecondExtension,
    MatchEventType.matchStartPenalty,
    MatchEventType.matchEnd,
  ].includes(event.type as MatchEventType);
}

export function isUsingSystemMatchEvents(match: MatchDetailItemFragment): boolean {
  return !!match.eventsStandard.find(e => e.type === MatchEventType.matchStart);
}

export interface MatchRound {
  round: number;
  matches: MatchListItemFragment[];
}

export function useMatchRounds(matches: MatchListItemFragment[]): MatchRound[] {
  return useMemo(() => {
    if (matches.length === 0) return [];

    let rounds: MatchRound[] = [];
    matches.forEach(match => {
      const index = rounds.findIndex(r => r.round === match.round);
      if (index === -1) {
        rounds = produce(rounds, draftState => {
          draftState.push({ round: match.round, matches: [match] });
        });
      } else {
        rounds = produce(rounds, draftState => {
          draftState[index].matches.push(match);
        });
      }
    });

    return [...rounds].sort((a, b) => {
      if (a.round > b.round) return 1;
      if (a.round === b.round) return 0;
      return -1;
    });
  }, [matches]);
}

export type SortedMatchEventSection = {
  team: TeamListItemFragment;
  goals: {
    standard: MatchEventFragment[];
    extension: MatchEventFragment[];
    penalty: MatchEventFragment[];
  };
  other: {
    standard: MatchEventFragment[];
    extension: MatchEventFragment[];
    penalty: MatchEventFragment[];
  };
};

export type SortedMatchEvents = {
  home: SortedMatchEventSection;
  visiting: SortedMatchEventSection;
};

export function useSortedMatchEvents(match: MatchDetailItemFragment, homeTeamId: string) {
  return useMemo<SortedMatchEvents>(() => {
    const eventsStandard = match.eventsStandard.filter(e => !isSystemMatchEvent(e));
    const eventsExtension = match.eventsExtension.filter(e => !isSystemMatchEvent(e));
    const eventsPenalty = match.eventsPenalty.filter(e => !isSystemMatchEvent(e));
    return {
      home: {
        team: match.home?.team,
        goals: {
          standard: eventsStandard.filter(
            ({ teamId, type, isOwnGoal }) =>
              (teamId === homeTeamId && type === MatchEventType.goal && !isOwnGoal) ||
              (teamId !== homeTeamId && type === MatchEventType.goal && isOwnGoal),
          ),
          extension: eventsExtension.filter(
            ({ teamId, type, isOwnGoal }) =>
              (teamId === homeTeamId && type === MatchEventType.goal && !isOwnGoal) ||
              (teamId !== homeTeamId && type === MatchEventType.goal && isOwnGoal),
          ),
          penalty: eventsPenalty.filter(
            ({ teamId, type, isOwnGoal }) =>
              (teamId === homeTeamId && type === MatchEventType.goal && !isOwnGoal) ||
              (teamId !== homeTeamId && type === MatchEventType.goal && isOwnGoal),
          ),
        },
        other: {
          standard: eventsStandard.filter(
            ({ teamId, type }) => teamId === homeTeamId && type !== MatchEventType.goal,
          ),
          extension: eventsExtension.filter(
            ({ teamId, type }) => teamId === homeTeamId && type !== MatchEventType.goal,
          ),
          penalty: eventsPenalty.filter(
            ({ teamId, type }) => teamId === homeTeamId && type !== MatchEventType.goal,
          ),
        },
      },
      visiting: {
        team: match.visiting?.team,
        goals: {
          standard: eventsStandard.filter(
            ({ teamId, type, isOwnGoal }) =>
              (teamId !== homeTeamId && type === MatchEventType.goal && !isOwnGoal) ||
              (teamId === homeTeamId && type === MatchEventType.goal && isOwnGoal),
          ),
          extension: eventsExtension.filter(
            ({ teamId, type, isOwnGoal }) =>
              (teamId !== homeTeamId && type === MatchEventType.goal && !isOwnGoal) ||
              (teamId === homeTeamId && type === MatchEventType.goal && isOwnGoal),
          ),
          penalty: eventsPenalty.filter(
            ({ teamId, type, isOwnGoal }) =>
              (teamId !== homeTeamId && type === MatchEventType.goal && !isOwnGoal) ||
              (teamId === homeTeamId && type === MatchEventType.goal && isOwnGoal),
          ),
        },
        other: {
          standard: eventsStandard.filter(
            ({ teamId, type }) => teamId !== homeTeamId && type !== MatchEventType.goal,
          ),
          extension: eventsExtension.filter(
            ({ teamId, type }) => teamId !== homeTeamId && type !== MatchEventType.goal,
          ),
          penalty: eventsPenalty.filter(
            ({ teamId, type }) => teamId !== homeTeamId && type !== MatchEventType.goal,
          ),
        },
      },
    };
  }, [match, homeTeamId]);
}

export const LIMIT = 40;

const apiKey = `/api/match`;
export const getCacheKey =
  ({
    season,
    league,
    team,
    player,
    phase,
    dateFrom,
    dateTo,
    round,
    referee,
    sortBy,
  }: TeamMatchesFilterData & {
    team?: string;
    player?: string;
    phase?: string;
    dateFrom?: string;
    dateTo?: string;
    round?: number;
    referee?: string;
    sortBy?: 'round' | 'time';
  }) =>
  (pageIndex: number, prevPageData: MatchListItemFragment[] | null) => {
    if (!league) return null;

    const params = new URLSearchParams({
      offset: `${LIMIT * pageIndex}`,
      sortBy: sortBy ?? 'round',
    });

    if (season && season !== '-') params.append('season', season);
    if (league && league !== '-') params.append('league', league);
    if (team) params.append('team', team);
    if (player) params.append('player', player);
    if (phase) params.append('phase', phase);
    if (dateFrom) params.append('dateFrom', dateFrom);
    if (dateTo) params.append('dateTo', dateTo);
    if (round) params.append('round', `${round}`);
    if (referee) params.append('referee', referee);

    if (prevPageData === null || pageIndex === 0) {
      return `${apiKey}?${params.toString()}`;
    }

    if (prevPageData.length < LIMIT) {
      return null;
    }

    return `${apiKey}/?${params.toString()}`;
  };

export async function getMatches(
  leagueId: string | null,
  offset: number = 0,
  limit: number = LIMIT,
  teamId: string | null = null,
  playerId: string | null = null,
  phaseId: string | null = null,
  dateFrom: number | null = null,
  dateTo: number | null = null,
  round: number | null = null,
  refereeId: string | null = null,
  seasonId: string | null = null,
  orderBy: MatchSortOrder | null = null,
): Promise<MatchListItemFragment[]> {
  const { matches } = await sdk.MatchList({
    union: process.env.NEXT_PUBLIC_UNION,
    player: playerId,
    team: teamId,
    league: leagueId ?? null,
    season: seasonId ?? null,
    phase: phaseId,
    referee: refereeId,
    round,
    dateFrom,
    dateTo,
    limit,
    offset,
    orderBy,
  });
  return matches;
}

export const MATCH_LIVE_SUBSCRIPTION = gql`
  subscription LiveMatch($id: ID!, $union: ID!) {
    liveMatch(union: $union, id: $id) {
      id
      event {
        id
        title
        minute
        type
        redAfterYellowCard
        isOwnGoal
        isGoalFromPenalty
        method
        message
        teamId
        teamName
        offenseId
        player {
          id
          givenName
          familyName
          fullName
        }
        assistant {
          id
          givenName
          familyName
          fullName
        }
        goalKeeper {
          id
          givenName
          familyName
          fullName
        }
        successGoal
        penaltyMethod
      }
    }
  }
`;
