export enum AppLinks {
  homepage = '/',
  newsList = '/news',
  drawList = '/draw',
  team = '/team',
  club = '/club',
  player = '/player',
  match = '/match',
  table = '/table',
  stats = '/stats',
  statsLeague = '/stats/league',
  matches = '/matches',
  referee = '/referee',
}
